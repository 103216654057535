import {
  DEFAULT_ALT,
  NextImage,
  noForwardCustomProps,
  noForwardProps
} from '@apeiron/temp'
import { Box, styled, Tab, Tabs, Typography } from '@mui/material'
import { ROUTE } from '@src/constants/route'
import Link from 'next/link'
import * as R from 'ramda'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const StyledTabs = styled(Tabs)`
  .Mui-disabled {
    opacity: 0.1 !important; // override js on mui
  }
  .MuiTabs-indicator {
    background-color: #ffb752;
    height: 3px;
  }
  background-color: #4a4d5e;
  padding: 0px 15px;
`

const StyledTab = styled(Tab, noForwardProps)<TabProps>`
  min-width: 160px;
  gap: 4px;
  min-height: ${props => props.__height}px;
  opacity: 1;
`

const Icon = styled(Box)`
  width: 36px;
  aspect-ratio: 1;
`

const StyledNextImage = styled(NextImage)`
  width: 100%;
  height: 100%;
`

const Text = styled(
  Typography,
  noForwardCustomProps(['iconAdjust'])
)<TextProps>`
  color: white;
  font-size: 16px;
  text-transform: none;
  ${props => (props.iconAdjust ? 'margin-right: 18px;' : '')}
`

const IsActiveDot = styled(Box)`
  height: 8px;
  width: 8px;
  background-color: #0086ed;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 12px;
`

const NavigationBar: FC<Props> = (props: Props) => {
  const { index = 0, height = 56, navigationItems = [] } = props

  const { t } = useTranslation()

  return (
    <StyledTabs value={index} orientation={'horizontal'} variant='scrollable'>
      {navigationItems.map((item: NavigationItem, index: number) => {
        const { icon, route, translationKey } = item

        return (
          <StyledTab
            __height={height}
            LinkComponent={Link}
            key={route}
            href={route}
            iconPosition='start'
            label={<Text iconAdjust={!R.isNil(icon)}>{t(translationKey)}</Text>}
            value={index}
            {...(icon
              ? {
                  icon: (
                    <>
                      <Icon>
                        <StyledNextImage alt={DEFAULT_ALT} src={icon} />
                      </Icon>
                      {item.isActive && <IsActiveDot />}
                    </>
                  )
                }
              : {})}
          />
        )
      })}
    </StyledTabs>
  )
}

type TextProps = {
  iconAdjust: boolean
}

type TabProps = {
  __height: number
  href: string
}

type Props = {
  index?: number
  height?: number
  navigationItems?: NavigationItem[]
}

export type NavigationItem = {
  icon?: string
  route: ROUTE | string
  translationKey: string
  isActive?: boolean
}

export default NavigationBar
