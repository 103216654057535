import { pageInfoLiteSchema } from '@apeiron/temp'
import {
  BattleDemoFragmentType,
  BattleDemoPlayMode,
  BattleDemoRewardSetType,
  GUILD_WAR_LEADERBOARD_NO_VALUE
} from '@src/constants/battleDemo'
import { GuildType } from '@src/constants/guild'
import { guildSchema } from '@src/deserialize/yup/guild'
import { planetClassSchema } from '@src/deserialize/yup/planet'
import {
  BattleDemoAbout,
  BattleDemoAboutCard,
  BattleDemoFragment,
  BattleDemoGuildLeaderboard,
  BattleDemoGuildPvERank,
  BattleDemoGuildPvPRank,
  BattleDemoGuildRank,
  BattleDemoLeaderboard,
  BattleDemoLeaderboardRecord,
  BattleDemoLeaderboardSummary,
  BattleDemoNews,
  BattleDemoNewsList,
  BattleDemoRank,
  BattleDemoRankPlanet,
  BattleDemoRankUser,
  BattleDemoReward,
  BattleDemoRewardGroup,
  BattleDemoRewardLite,
  BattleDemoRewardSet,
  BattleDemoSeason,
  ClaimableCurrencyDatatype
} from '@src/types/battleDemo'
import * as R from 'ramda'
import * as yup from 'yup'

const fragmentTypeSchema: yup.BaseSchema<BattleDemoFragmentType> = yup
  .mixed()
  .oneOf(Object.values(BattleDemoFragmentType))
  .default(BattleDemoFragmentType.Solar)

const battleDemoRewardSetTypeSchema: yup.BaseSchema<BattleDemoRewardSetType> =
  yup
    .mixed()
    .oneOf(Object.values(BattleDemoRewardSetType))
    .default(BattleDemoRewardSetType.Animus)

const newsSchema: yup.SchemaOf<BattleDemoNews> = yup.object({
  date: yup.string().default(''),
  content: yup.string().default(''),
  thumbnail: yup.string().default(''),
  banner: yup.string().default(''),
  title: yup.string().default(''),
  description: yup.string().default('')
})

export const battleDemoNewsSchema: yup.SchemaOf<BattleDemoNewsList> =
  yup.object({
    news: yup.array().of(newsSchema),
    patches: yup.array().of(newsSchema)
  })

export const battleDemoFragmentsSchema: yup.SchemaOf<BattleDemoFragment> = yup
  .object({
    burn: yup.number().default(0),
    burnRatio: yup.number().default(0),
    id: yup.string().default(''),
    name: yup.string().default(''),
    openSeaUrl: yup.string().default(''),
    total: yup.number().default(0),
    type: fragmentTypeSchema
  })
  .camelCase()

const battleDemoPlayModeSchema: yup.BaseSchema<BattleDemoPlayMode> = yup
  .mixed()
  .oneOf(Object.values(BattleDemoPlayMode))
  .default(BattleDemoPlayMode.Speedrun)

const battleDemoLeaderboardSummarySchema: yup.SchemaOf<BattleDemoLeaderboardSummary> =
  yup
    .object({
      end: yup.string().default(''),
      name: yup.string().default(''),
      offSeason: yup.boolean().default(false),
      playMode: battleDemoPlayModeSchema,
      season: yup.string().default(''),
      start: yup.string().default('')
    })
    .when(['$data'], (data: Record<string, any>) => {
      return yup.object().default({
        end: R.pathOr('', ['period', 'end'], data),
        name: R.pathOr('', ['rewardInfo', 'name'], data),
        offSeason: R.pathOr(false, ['rewardInfo', 'off_season'], data),
        playMode: R.propOr('', 'category', data),
        season: R.propOr('', 'leaderboardType', data),
        start: R.pathOr('', ['period', 'start'], data)
      })
    })

const battleDemoRankUserSchema: yup.SchemaOf<BattleDemoRankUser> = yup.object({
  walletAddress: yup.string().default(''),
  name: yup.string().default(''),
  tag: yup.number().default(0)
})

const battleDemoRankPlanetSchema: yup.SchemaOf<BattleDemoRankPlanet> = yup
  .object({
    icon: yup.string().default(''),
    id: yup.string().default(''),
    planetClass: planetClassSchema
  })
  .from('classIcon', 'icon')
  .from('metaKey', 'planetClass')

const battleDemoRankSchema: yup.SchemaOf<BattleDemoRank> = yup.object({
  id: yup
    .string()
    .when(['user'], (user: Record<string, any>) => {
      const name = R.replace(' ', '', R.toLower(user.name))

      return yup.string().default(`${name}-${user.tag}`)
    })
    .default(''),
  planet: battleDemoRankPlanetSchema,
  rank: yup.number().default(0),
  user: battleDemoRankUserSchema,
  score: yup.number().default(0)
})

const battleDemoLeaderboardRecordSchema: yup.SchemaOf<BattleDemoLeaderboardRecord> =
  yup
    .object({
      pageInfo: pageInfoLiteSchema,
      playerRank: battleDemoRankSchema.nullable().default(null),
      ranks: yup.array().of(battleDemoRankSchema).default([])
    })
    .from('playerRecord', 'playerRank')
    .from('globalRecords', 'ranks')

export const battleDemoRewardLiteSchema: yup.SchemaOf<BattleDemoRewardLite> =
  yup
    .object({
      name: yup.string().default(''),
      value: yup.number().default(0)
    })
    .from('quantity', 'value')
    .noUnknown(true)

const battleDemoRewardGroupSchema: yup.SchemaOf<BattleDemoRewardGroup> = yup
  .object({
    backgroundColor: yup.string().default('purple'),
    rankMax: yup.number().default(1),
    rankMin: yup.number().default(1),
    rewardsBasic: yup.array().of(battleDemoRewardLiteSchema).default([]),
    rewardsBonus: yup.array().of(battleDemoRewardLiteSchema).default([])
  })
  .camelCase()

export const battleDemoRewardLiteV2Schema: yup.SchemaOf<BattleDemoRewardLite> =
  yup
    .object({
      name: yup.string().default(''),
      value: yup.number().default(0)
    })
    .from('metaKey', 'name')
    .from('qty', 'value')

const battleDemoRewardGroupV2Schema: yup.SchemaOf<BattleDemoRewardGroup> = yup
  .object({
    rankMax: yup.number().default(1),
    rankMin: yup.number().default(1),
    rewardsBasic: yup.array().of(battleDemoRewardLiteV2Schema).default([]),
    rewardsBonus: yup.array().of(battleDemoRewardLiteV2Schema).default([])
  })
  .from('fromRank', 'rankMin')
  .from('toRank', 'rankMax')
  .from('rewards', 'rewardsBasic')

export const battleDemoLeaderboardSchema: yup.SchemaOf<BattleDemoLeaderboard> =
  yup
    .object({
      summary: battleDemoLeaderboardSummarySchema,
      record: battleDemoLeaderboardRecordSchema,
      rewardGroups: yup.array().of(battleDemoRewardGroupSchema).default([])
    })
    .from('recordInfo', 'record')
    .from('rewardInfo.reward_info', 'rewardGroups', true)
    .noUnknown(true)

const battleDemoGuildRankSchema: yup.SchemaOf<BattleDemoGuildRank> = yup
  .object({
    guild: guildSchema,
    id: yup.string().default(''),
    rank: yup.number().default(GUILD_WAR_LEADERBOARD_NO_VALUE)
  })
  .from('guild.id', 'id', true)
  .noUnknown(true)

const battleDemoGuildPvERankSchema: yup.SchemaOf<BattleDemoGuildPvERank> =
  battleDemoGuildRankSchema.concat(
    yup
      .object({
        clearRoom: yup.number().default(GUILD_WAR_LEADERBOARD_NO_VALUE),
        clearTime: yup.number().default(GUILD_WAR_LEADERBOARD_NO_VALUE),
        clearTotalRoom: yup.number().default(GUILD_WAR_LEADERBOARD_NO_VALUE)
      })
      .from('best3.maxRooms', 'clearRoom')
      .from('best3.bestTime', 'clearTime')
      .from('guildTotal.maxRooms', 'clearTotalRoom')
      .noUnknown(true)
  )

const battleDemoGuildPvPRankSchema: yup.SchemaOf<BattleDemoGuildPvPRank> =
  battleDemoGuildRankSchema.concat(
    yup
      .object({
        matchMakingRate: yup.number().default(GUILD_WAR_LEADERBOARD_NO_VALUE),
        totalStar: yup.number().default(GUILD_WAR_LEADERBOARD_NO_VALUE)
      })
      .from('best3.mmr', 'matchMakingRate')
      .from('guildTotal.stars', 'totalStar')
      .noUnknown(true)
  )

export const battleDemoGuildMyRankSchema = (type: GuildType) => {
  const rankSchema =
    type === GuildType.PvE
      ? battleDemoGuildPvERankSchema
      : battleDemoGuildPvPRankSchema

  return battleDemoGuildRankSchema.when(
    ['$data'],
    (data: Record<string, any>): yup.SchemaOf<BattleDemoGuildRank> => {
      const index = R.path(['record', 'userGuildRank', 'rankingIndex'], data)

      const myRank =
        R.path(['record', 'leaderboard', index], data) ||
        R.path(['record', 'userGuildRank'], data)

      return rankSchema.nullable(true).transform(() => {
        const schema = rankSchema.nullable(true)

        return schema.cast(myRank)
      })
    }
  )
}

export const battleDemoGuildPvELeaderboardSchema: yup.SchemaOf<
  BattleDemoGuildLeaderboard<BattleDemoGuildPvERank>
> = yup
  .object({
    myRank: battleDemoGuildMyRankSchema(GuildType.PvE).nullable().default(null),
    name: yup.string().default(''),
    ranks: yup.array().of(battleDemoGuildPvERankSchema).default([]),
    rewardGroups: yup.array().of(battleDemoRewardGroupSchema).default([])
  })
  .from('record.leaderboard', 'ranks')
  .from('record.userGuildRank', 'myRank')
  .from('rewardInfo.name', 'name')
  .from('rewardInfo.reward_info', 'rewardGroups')
  .noUnknown(true)

export const battleDemoGuildPvPLeaderboardSchema: yup.SchemaOf<
  BattleDemoGuildLeaderboard<BattleDemoGuildPvPRank>
> = yup
  .object({
    myRank: battleDemoGuildMyRankSchema(GuildType.PvP).nullable().default(null),
    name: yup.string().default(''),
    ranks: yup.array().of(battleDemoGuildPvPRankSchema).default([]),
    rewardGroups: yup.array().of(battleDemoRewardGroupSchema).default([])
  })
  .from('record.leaderboard', 'ranks')
  .from('record.userGuildRank', 'myRank')
  .from('rewardInfo.name', 'name')
  .from('rewardInfo.reward_info', 'rewardGroups')
  .noUnknown(true)

const battleDemoAboutCardSchema: yup.SchemaOf<BattleDemoAboutCard> = yup
  .object({
    description: yup.string().default(''),
    image: yup.string().default(''),
    title: yup.string().default(''),
    imageAspectRatio: yup.number().default(1)
  })
  .from('image_aspect_ratio', 'imageAspectRatio')

export const battleDemoAboutSchema: yup.SchemaOf<BattleDemoAbout> = yup
  .object({
    apeironTitle: yup.string().default(''),
    content: yup.array().of(battleDemoAboutCardSchema).default([]),
    title: yup.string().default('')
  })
  .camelCase()

export const battleDemoPvESeasonSchema: yup.SchemaOf<BattleDemoSeason> = yup
  .object({
    active: yup.boolean().default(false),
    icon: yup.string().nullable().default(null),
    id: yup.string().default(''),
    name: yup.string().default(''),
    value: yup.string().default(''),
    rewardGroups: yup
      .array()
      .of(battleDemoRewardGroupV2Schema)
      .nullable()
      .default(null)
  })
  .from('isActive', 'active')
  .from('leaderboardType', 'id', true)
  .from('leaderboardType', 'value')
  .from('metaKey', 'name')
  .from('seasonalReward', 'rewardGroups')
  .noUnknown(true)

export const battleDemoPvPSeasonSchema: yup.SchemaOf<BattleDemoSeason> = yup
  .object({
    active: yup.boolean().default(false),
    icon: yup.string().nullable().default(null),
    id: yup.string().default(''),
    name: yup.string().default(''),
    value: yup.string().default(''),
    rewardGroups: yup
      .array()
      .of(battleDemoRewardGroupV2Schema)
      .nullable()
      .default(null)
  })
  .from('isActive', 'active')
  .from('name', 'id', true)
  .from('name', 'value', true)
  .from('seasonalReward', 'rewardGroups')
  .noUnknown(true)

export const battleDemoRewardSchema: yup.SchemaOf<BattleDemoReward> = yup
  .object({
    image: yup.string().default(''),
    name: yup.string().default(''),
    value: yup.number().default(0),
    claimDatatype: yup.mixed().oneOf(Object.values(ClaimableCurrencyDatatype)),
    source: yup.string().default('')
  })
  .from('metaType.image', 'image')
  .from('metaType.name', 'name')
  .from('metaType.source', 'source')
  .noUnknown(true)

export const battleDemoRewardSetSchema: yup.SchemaOf<BattleDemoRewardSet> = yup
  .object({
    items: yup.array().of(battleDemoRewardSchema).default([]),
    type: battleDemoRewardSetTypeSchema
  })
  .from('claimType', 'type')
  .from('data', 'items')
  .noUnknown(true)
